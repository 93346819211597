import { Box, Flex, Skeleton, VStack } from '@chakra-ui/react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { useOpenFundWizard } from '@endaoment-frontend/fund-wizard';
import type { FundListing } from '@endaoment-frontend/types';
import { MigrateIcon, NewFundIcon } from '@endaoment-frontend/ui/icons';
import { ActionButton, Card, EmptyStateBlock } from '@endaoment-frontend/ui/shared';
import { arrToSorted } from '@endaoment-frontend/utils';

import { FundStatsCard, FundStatsCardSkeleton } from '../../components/FundStatsCard';
import { FundMigrationWarning } from '../funds/id/FundMigrationWarning';

import styles from './FundsSection.module.scss';

const DEFAULT_IS_COLLABORATOR = () => false;
export const FundsSection = ({
  funds,
  displayStats = true,
  className,
  isCollaboratorOnFund = DEFAULT_IS_COLLABORATOR,
}: {
  funds?: Array<FundListing>;
  displayStats?: boolean;
  className?: string;
  isCollaboratorOnFund?: (fund: FundListing) => boolean;
}) => {
  const openFundWizard = useOpenFundWizard();

  if (!funds) {
    return (
      <Card className={clsx(styles['container'], className)} mobileFullWidth>
        <VStack alignItems='stretch'>
          <Skeleton w={40} minH={12} />
          <Skeleton w='100%' minH={4} mt='0.5rem' />
          <Flex justifyContent='space-between' className={styles['progress']}>
            <Skeleton width={30} minH={4} />
            <Skeleton width={30} minH={4} />
          </Flex>
        </VStack>
        <Box mb='1rem' mt='1.5rem'>
          <hr />
        </Box>
        <VStack
          className={clsx(styles['funds-list-container'], styles['funds-list-container--loading'])}
          alignItems='stretch'
          gap='1rem'
          mt='0.5rem'>
          <FundStatsCardSkeleton />
          <FundStatsCardSkeleton />
        </VStack>
      </Card>
    );
  }

  return (
    <Card className={clsx(styles['container'], className)} mobileFullWidth>
      <div className={styles['funds-label']}>
        <h2>Funds</h2>
      </div>
      <AnimatePresence>
        {!!displayStats && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            style={{ overflow: 'hidden' }}>
            <FundStatsCard fund={funds} className={styles['stats']}>
              {/* Used to prevent button from appearing */}
              <></>
            </FundStatsCard>
          </motion.div>
        )}
      </AnimatePresence>
      <div className={styles['funds-list-container']}>
        <FundMigrationWarning funds={funds} />

        <ul className={styles['funds-list']}>
          {funds.length > 0 ? (
            <>
              {arrToSorted(funds, (f1, f2) => (f1.lifetimeDonationsUsdc > f2.lifetimeDonationsUsdc ? -1 : 1)).map(
                fund => (
                  <FundStatsCard
                    key={fund.id}
                    fund={fund}
                    asCard
                    isLabelled
                    isCollaboratorOnFund={isCollaboratorOnFund(fund)}
                  />
                ),
              )}
              <ActionButton
                text='Migrate a DAF'
                subtext='Move a portion of funds or your entire DAF from another provider'
                onClick={() => openFundWizard({ initialMode: 'migrate' })}
                color='purple'
                className={styles['action-button']}>
                <MigrateIcon width={36} color='currentColor' />
              </ActionButton>
              <ActionButton
                text='Start a New Fund'
                subtext='Completely free to setup, we only need your name and address'
                onClick={() => openFundWizard({ initialMode: 'create' })}
                color='fund'
                className={clsx(styles['action-button'], styles['action-button__spinny'])}>
                <NewFundIcon width={34} color='currentColor' fillOpacity='1' />
              </ActionButton>
            </>
          ) : (
            <EmptyStateBlock
              title={funds.length > 0 ? 'No matching funds' : 'No open funds'}
              variation='research'
              description='Create a donor-advised fund to recommend grants to over 1.8 million nonprofit organizations.'
              vertical
            />
          )}
        </ul>
      </div>
    </Card>
  );
};
